import { axiosClient } from 'utils/axiosClient';

const uploadProspectEndpoint = 'v1/prospects/upload';

export const createProspectCallBackRequest = (id, dateTimeRequested) => {
  return axiosClient
    .post(`v1/prospect/${id}/call_back`, {
      date_time_requested: dateTimeRequested || null
    })
    .then((resp) => resp?.data);
};

export const updateProspectCallBackRequest = (id, params) => {
  return axiosClient
    .put(`v1/prospect/call_back/${id}`, {
      call_made: params.callMade || false,
      call_received: params.callReceived || false,
      remarks: params?.remarks || '',
      status: params?.status || ''
    })
    .then((resp) => resp?.data);
};

export const updateProspect = (id, params) => {
  return axiosClient
    .put(`v1/prospect/${id}/update`, {
      temperature: params?.temperature || ''
    })
    .then((resp) => resp?.data);
};

export const uploadProspect = (formData) => {
  return axiosClient.post(uploadProspectEndpoint, formData).then((resp) => resp?.data);
};

export const getProspects = (page = 0, limit = 10) => {
  return axiosClient
    .get(`v1/prospects/upload_script/history?limit=${limit}&offset=${page * limit}`)
    .then((resp) => resp?.data);
};

export const getBuyerProspectList = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    rewm,
    createdFromDate,
    createdToDate,
    minPriceRange,
    maxPriceRange,
    sortAt,
    sortBy,
    temperature,
    buyerSource,
    updatedFromDate,
    updatedToDate,
    rm,
    buyerStatus,
    buyerType
  } = filters;

  const params = {
    add_from_date: createdFromDate,
    add_to_date: createdToDate,
    buyers_type: buyerType,
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    price_max: maxPriceRange,
    price_min: minPriceRange,
    sort_at: sortAt,
    sort_by: sortBy,
    source: buyerSource,
    status: buyerStatus,
    temperature: temperature,
    trm: rm,
    updated_from_date: updatedFromDate,
    updated_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient
    .get('v1/transaction_order/buy_prospects' + queryString)
    .then((resp) => resp?.data);
};

export const getProspectiveBuyersDropdownValues = () => {
  return axiosClient.get('v1/prospects/dropdown/values').then((resp) => resp?.data);
};

export const addProspectiveBuyer = (params) => {
  return axiosClient.post('v1/prospect/add', params).then((resp) => resp?.data);
};

export const addCallbackRemarks = (callbackId, params) => {
  return axiosClient
    .post(`v1/prospect/call_back/${callbackId}/remarks`, params)
    .then((resp) => resp?.data);
};

export const getChannelPartnerList = () => {
  return axiosClient.get(`/v2/channel_partner_listing`).then((resp) => resp?.data);
};

export const getChannelPartner = (id) => {
  return axiosClient.get(`/v2/channel_partner/${id}/details`).then((resp) => resp?.data);
};

export const addChannelPartnerRM = (params) => {
  return axiosClient.post('/v2/channel_partner/rm/add', params).then((resp) => resp?.data);
};

export const uploadDocument = (id, file) => {
  return axiosClient.post(`/v2/channel_partner/${id}/fileupload`, file).then((resp) => resp?.data);
};

export const addChannelPartner = (params) => {
  return axiosClient.post('/v2/channel_partner/add', params).then((resp) => resp?.data);
};

export const updateChannelPartner = (id, params) => {
  return axiosClient.put(`/v2/channel_partner/${id}/update`, params).then((resp) => resp?.data);
};

export const deleteDocument = (id, fileId) => {
  return axiosClient
    .delete(`/v2/channel_partner/${id}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const getChannelPartnerVerticalList = () => {
  return axiosClient.get(`/v2/channel_partner_vertical_listing`).then((resp) => resp?.data);
};
