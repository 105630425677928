import { createSlice } from '@reduxjs/toolkit';
import {
  getBranchHeadDropdownValues,
  getBrokerDropdownValues,
  getCallbackDropdownValues,
  getChannelPartnersDropdownValues,
  getChannelPartnersSourcesDropdownValues,
  getChannelPartnerVerticalValues,
  getCitiesDropDownValues,
  getCountriesDropDownValues,
  getEmployeeDropdownValues,
  getEventDropdownValues,
  getLeadManagersDropdownValues,
  getLeadsDropdownValues,
  getLoanStatusValues,
  getNeighbourhoodPreferencesDropdownValues,
  getOrderStatusDropdownValues,
  getPaymentDropdownValues,
  getPreferencesDropdownValues,
  getProductsCatalogValues,
  getProfileDropdownValues,
  getPropertiesDropdownValues,
  getQuickAccessValues,
  getStatesDropDownValues,
  getTrendingLocationsValues
} from 'api/dropdown';

const dropdownSlice = createSlice({
  initialState: {
    branchHeadDropdownValues: [],
    callbackDropdownValues: [],
    channelPartnersDropdownValues: [],
    countriesDropdownValues: [],
    leadDropdownValues: [],
    paymentDropdownValues: [],
    preferenceDropdownValues: [],
    profileDropdownValues: [],
    sourceTypesDropdownValues: []
  },
  name: 'dropdown',
  reducers: {
    setBranchHeadDropdownValues(state, action) {
      state.branchHeadDropdownValues = action.payload;
    },
    setCallbackDropdownValues(state, action) {
      state.callbackDropdownValues = action.payload;
    },
    setChannelPartnersDropdownValues(state, action) {
      state.channelPartnersDropdownValues = action.payload;
    },
    setCountriesDropdownValues(state, action) {
      state.countriesDropdownValues = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLeadDropdownValues(state, action) {
      state.leadDropdownValues = action.payload;
    },
    setPaymentDropdownValues(state, action) {
      state.paymentDropdownValues = action.payload;
    },
    setPreferenceDropdownValues(state, action) {
      state.preferenceDropdownValues = action.payload;
    },
    setProfileDropdownValues(state, action) {
      state.profileDropdownValues = action.payload;
    },
    setSourceTypesDropdownValues(state, action) {
      state.sourceTypesDropdownValues = action.payload;
    }
  }
});

export const dropdownActions = dropdownSlice.actions;

export const handleGetPropertiesDropdownValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getPropertiesDropdownValues();
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetPreferencesDropdownValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getPreferencesDropdownValues();
    dispatch(dropdownActions.setPreferenceDropdownValues(data));

    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetProfileDropdownValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getProfileDropdownValues();
    dispatch(dropdownActions.setProfileDropdownValues(data));
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetProductsCatalogValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getProductsCatalogValues();
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetTrendingLocationsValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getTrendingLocationsValues();
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetQuickAccessValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getQuickAccessValues();
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetNeighbourhoodPreferencesDropdownValues = () => async (dispatch) => {
  try {
    dispatch(dropdownActions.setIsLoading(true));
    const data = await getNeighbourhoodPreferencesDropdownValues();
    return data;
  } catch (error) {
    dispatch(dropdownActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dropdownActions.setIsLoading(false));
  }
};

export const handleGetCountriesDropDownValues = () => async (dispatch) => {
  try {
    const data = await getCountriesDropDownValues();
    dispatch(dropdownActions.setCountriesDropdownValues(data?.[0]?.countries));

    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetStatesDropDownValues = (countryCode) => async () => {
  try {
    const data = await getStatesDropDownValues(countryCode);
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetCitiesDropDownValues = (countryCode, stateCode) => async () => {
  try {
    const data = await getCitiesDropDownValues(countryCode, stateCode);
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetLeadsDropdownValues = () => async (dispatch) => {
  try {
    const data = await getLeadsDropdownValues();
    dispatch(dropdownActions.setLeadDropdownValues(data));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetChannelPartnersDropdownValues = () => async (dispatch) => {
  try {
    const data = await getChannelPartnersDropdownValues();
    dispatch(dropdownActions.setChannelPartnersDropdownValues(data?.[0]?.ChannelPartner));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetOrderStatusDropdownValues = () => async () => {
  try {
    const data = await getOrderStatusDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetAdvisoryDropdownValues = () => async () => {
  try {
    const data = await getOrderStatusDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetPaymentDropdownValues = () => async (dispatch) => {
  try {
    const data = await getPaymentDropdownValues();
    dispatch(dropdownActions.setPaymentDropdownValues(data));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetEventDropdownValues = () => async () => {
  try {
    const data = await getEventDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetEmployeeDropdownValues = () => async () => {
  try {
    const data = await getEmployeeDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetChannelPartnersSourcesDropdownValues = () => async (dispatch) => {
  try {
    const data = await getChannelPartnersSourcesDropdownValues();
    dispatch(dropdownActions.setSourceTypesDropdownValues(data?.source_type));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetBranchHeadDropdownValues = () => async (dispatch) => {
  try {
    const data = await getBranchHeadDropdownValues();
    dispatch(dropdownActions.setBranchHeadDropdownValues(data));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleCallbackDropdownValues = () => async (dispatch) => {
  try {
    const data = await getCallbackDropdownValues();
    dispatch(dropdownActions.setCallbackDropdownValues(data));
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleLoanStatusDropdownValues = () => async () => {
  try {
    const data = await getLoanStatusValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetLeadManagersDropdownValues = () => async () => {
  try {
    const data = await getLeadManagersDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetBrokersDropdownValues = () => async () => {
  try {
    const data = await getBrokerDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetCPVerticalValues = () => async () => {
  try {
    const data = await getChannelPartnerVerticalValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;
    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;
      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export default dropdownSlice;
